import {
  IDetailListItem,
  IFacDataItem
} from '@/service/production/equipmentLive/type';
import { IEquipmentListItemNew } from '@/service/vibrationEquipment/type';
import { Module } from 'vuex';
import { IRootState, IVibrationEquipmentModuleState } from '../type';
import localCache from '@/utils/localCache';

const vibrationEquipment: Module<IVibrationEquipmentModuleState, IRootState> = {
  namespaced: true,
  state: () => {
    return {
      drawList: [],
      useList: [],
      deviceGroup: [], // 当前选中菜单下的设备列表
      siderbar: [], // 当前菜单列表
      curSiderbarIndex: 0, // 当前选中菜单项的索引
      curSiderbarId: undefined // 当前选中菜单项的factoryId
    };
  },
  getters: {
    deviceGroupId(state) {
      return state.curSiderbarId;
    },
    deviceGroupList(state) {
      return state.deviceGroup; //state.deviceGroup[state.curSiderbarIndex]?.groupList ?? []
    }
  },
  mutations: {
    updateDeviceGroup(state, list: IFacDataItem[]) {
      state.deviceGroup = list;
    },
    updateSiderbar(state, list: IEquipmentListItemNew[]) {
      state.siderbar = list;
      localCache.setItem('vibrationSiderbar', JSON.stringify(list));
    },
    updateSiderbarIndex(state, index: number) {
      state.curSiderbarIndex = index;
      localCache.setItem('vibrationCurSiderbarIndex', index);
    },
    updateSiderbarId(state, Id: number) {
      state.curSiderbarId = Id;
      localCache.setItem('vibrationCurSiderbarId', Id);
    },
    updateDrawList(state, list: IDetailListItem[]) {
      state.drawList = list;
      localCache.setItem('drawList', JSON.stringify(list));
    },
    updateUseList(state, list: IDetailListItem[]) {
      state.useList = list;
      localCache.setItem('useList', JSON.stringify(list));
    }
  },
  actions: {}
};

export default vibrationEquipment;
